import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Гараж - автомобильное обслуживание и многое другое
			</title>
			<meta name={"description"} content={"Ваши автомобильные вопросы, ответил - гараж очищает дорогу\n"} />
			<meta property={"og:title"} content={"FAQ | Гараж - автомобильное обслуживание и многое другое"} />
			<meta property={"og:description"} content={"Ваши автомобильные вопросы, ответил - гараж очищает дорогу"} />
			<meta property={"og:image"} content={"https://qmist-juns.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://qmist-juns.com/img/4249144.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qmist-juns.com/img/4249144.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qmist-juns.com/img/4249144.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qmist-juns.com/img/4249144.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qmist-juns.com/img/4249144.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qmist-juns.com/img/4249144.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px" max-width="450px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Часто задаваемые вопросы
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						В гараже мы понимаем, что обслуживание автомобиля может поднять много вопросов.Вот ответы на некоторые общие запросы, которые дают наши клиенты, обеспечивая ясность и уверенность в наших услугах.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					padding="0px 0px 0px 40px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Какие виды транспортных средств вы обслуживаете?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Мы обслуживаем широкий спектр транспортных средств, в том числе как современные, так и классические автомобили.Наша команда имеет опыт в различных магах и моделях.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Как часто я должен обслуживать свой автомобиль?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Частота обслуживания может варьироваться в зависимости от типа вашего автомобиля и использования.Мы обычно рекомендуем осмотр каждые 6000 миль или хотя бы раз в год.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Вы предлагаете обновления производительности?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Да, мы предоставляем различные обновления производительности, включая настройку двигателей, улучшения подвески и многое другое, адаптированы к вашим конкретным потребностям.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Можете ли вы справиться с классическими реставрациями автомобилей?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Абсолютно!Наша команда увлечена возвращением классических автомобилей к жизни, предлагая комплексные услуги по восстановлению.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Что мне делать, если я испытываю проблемы с автомобилем?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Если вы заметите какие -либо проблемы с вашим автомобилем, лучше всего принести его для проверки диагностики как можно скорее, чтобы предотвратить дальнейшие осложнения.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Как я могу забронировать встречу с сервисом?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Вы можете записаться на прием, позвонив нам напрямую или отправив электронное письмо.Мы найдем время, которое лучше всего подходит для вас.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});